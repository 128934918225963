/* line 1, ../scss/IE6.scss */
abbr {
  display: inline; }

/**/
/* components/ui-dialog.css */
/* line 5, ../scss/IE6.scss */
.ui-dialog-message .dialog-message .section .details-item .reservations-listing {
  height: 200px;
  overflow: auto; }

/**/
/* components/ui-datepicker.css */
/* line 10, ../scss/IE6.scss */
.ui-datepicker {
  height: 200px;
  width: 154px; }

/* line 14, ../scss/IE6.scss */
.ui-datepicker iframe {
  display: none; }

/**/
/* components/ui-text-search.css */
/**/
/* elements */
/* line 19, ../scss/IE6.scss */
body {
  behavior: url(../js/csshover3.htc);
  /* path from html */ }

/**/
/* classes */
/* line 23, ../scss/IE6.scss */
.main .section .main-tabs {
  overflow: hidden; }

/* line 26, ../scss/IE6.scss */
.main .section .main-tabs li.active {
  margin-right: 30px; }

/* line 29, ../scss/IE6.scss */
.main .section .main-tabs li.active a {
  background-image: none;
  display: inline; }

/* line 33, ../scss/IE6.scss */
.main .section .main-tabs li.active a span {
  background-image: url(../Images/active-tab.png);
  background-position: right top;
  padding-right: 9px; }

/* line 39, ../scss/IE6.scss */
.secondary-actions ul.list-actions li,
.secondary-actions ul.list-actions li span {
  background-image: url(../Images/FIX-ul-list-actions-border-radius.png);
  background-repeat: no-repeat; }

/* line 44, ../scss/IE6.scss */
.secondary-actions ul.list-actions li {
  background-position: right 50%;
  padding-left: 0; }

/* line 48, ../scss/IE6.scss */
.secondary-actions ul.list-actions li span {
  background-position: left 50%;
  display: block;
  padding-left: 8px; }

/* line 53, ../scss/IE6.scss */
.secondary-actions ul.list-actions li:hover ul {
  display: block; }

/* line 56, ../scss/IE6.scss */
.secondary-actions ul.list-actions li ul li {
  background-image: none;
  padding-left: 8px; }

/* line 60, ../scss/IE6.scss */
.form-buttons {
  height: 100%;
  overflow: hidden; }

/* line 64, ../scss/IE6.scss */
.form-buttons input.clear-bttn,
.form-buttons input.save-bttn {
  background-image: url(../Images/FIX-form-buttons.png);
  background-repeat: no-repeat; }

/* line 69, ../scss/IE6.scss */
.form-buttons input.clear-bttn {
  background-position: center bottom; }

/* line 72, ../scss/IE6.scss */
.form-buttons input.save-bttn {
  background-position: center top; }

/* MAIN-CONTENT */
/* line 76, ../scss/IE6.scss */
table.message-list th.col-roomtype,
table.message-list th.col-date,
table.message-list th.col-status,
table.message-list th.col-extradate {
  -iefix-before: expression( (typeof this.iebefore == "undefined") ? (this.iebefore = document.createElement("<before>"), this.insertBefore(this.iebefore, this.firstChild), this.iebefore.innerHTML = "", "true" ) : "true" ); }

/* line 90, ../scss/IE6.scss */
table.message-list th.col-roomtype before,
table.message-list th.col-date before,
table.message-list th.col-status before,
table.message-list th.col-extradate before {
  content: "";
  height: 80%;
  border-left: solid 1px #d4d4d4;
  margin-right: 5px; }

/* line 99, ../scss/IE6.scss */
table.message-list th.col-input {
  -iefix-after: expression( (typeof this.ieafter == "undefined") ? (this.ieafter = document.createElement("<after>"), this.appendChild(this.ieafter), this.ieafter.innerHTML = "", "true" ) : "true" ); }

/* line 110, ../scss/IE6.scss */
table.message-list th.col-input {
  border-left: 1px solid #d4d4d4;
  content: "";
  height: 80%;
  margin-right: 5px; }

/* ADD NEW SALES REQUEST */
/* line 117, ../scss/IE6.scss */
.new-request .left-part .select-room-type strong {
  left: -80px; }

/* line 120, ../scss/IE6.scss */
.new-request .right-part .select-dates label {
  display: inline-block;
  margin-top: -2px;
  vertical-align: top; }

/* line 125, ../scss/IE6.scss */
.new-request .active-requests-listing .listing-header {
  height: 100%;
  overflow: hidden; }

/* line 129, ../scss/IE6.scss */
.new-request .active-requests-listing .col-2,
.new-request .active-requests-listing .col-3,
.new-request .active-requests-listing .col-4,
.new-request .active-requests-listing .col-5,
.new-request .active-requests-listing .col-6 {
  -iefix-before: expression( (typeof this.iebefore == "undefined") ? (this.iebefore = document.createElement("<before>"), this.insertBefore(this.iebefore, this.firstChild), this.iebefore.innerHTML = "", "true" ) : "true" ); }

/* line 144, ../scss/IE6.scss */
.new-request .active-requests-listing .col-2 before,
.new-request .active-requests-listing .col-3 before,
.new-request .active-requests-listing .col-4 before,
.new-request .active-requests-listing .col-5 before,
.new-request .active-requests-listing .col-6 before {
  border-left: 1px solid #d4d4d4;
  content: "";
  height: 80%;
  margin-right: 5px; }

/* ADD NEW SALES REQUEST MULTIPLE */
/* line 155, ../scss/IE6.scss */
.search-accommodations .search-header {
  height: 100%;
  overflow: hidden; }

/* line 159, ../scss/IE6.scss */
.request-multiple ul.list-accommodations {
  height: 100%;
  margin-left: 0;
  overflow: hidden;
  padding-bottom: 2em; }

/* line 165, ../scss/IE6.scss */
.request-multiple .search-accommodations ul.list-accommodations li {
  margin-left: 15px;
  margin-right: 15px;
  width: 345px; }

/* line 170, ../scss/IE6.scss */
.request-multiple .selected-accommodations ul.list-accommodations li {
  margin-left: 0; }

/*# sourceMappingURL=IE6.css.map */
